import React from 'react'
import { Disclosure } from '@headlessui/react'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { OutboundLink } from 'gatsby-plugin-google-gtag'
import HowDoIProgressPDF from '../forms/How-Do-I-Progress_09-24.pdf'
import '../css/class-policies.css'

export default function ClassPolicies() {
  return (
    <div className="w-full">
      <div className="w-full max-w-lg lg:max-w-full rounded-2xl bg-white">
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full pr-4 py-2 text-grey-darker text-left font-bold focus:outline-none">
                <ChevronRightIcon
                  className={`${open ? 'rotate-90' : ''} h-5 w-5`}
                />
                <span className="pl-2">Annual Registration Fee</span>
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pt-4 pb-2 text-grey-darker font-light">
                Families will be assessed a{' '}
                <strong className="font-bold">$30.00</strong> Annual
                Registration Fee in September for the school year along with the
                September tuition. Customers who enroll after September will be
                charged the $30.00 Annual Registration Fee with their first
                tuition payment.
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        <Disclosure as="div" className="mt-2">
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full pr-4 py-2 text-grey-darker text-left font-bold focus:outline-none">
                <ChevronRightIcon
                  className={`${open ? 'rotate-90' : ''} h-5 w-5`}
                />
                <span className="pl-2">Auto Enrolling & Billing</span>
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pt-4 pb-2 text-grey-darker font-light">
                Students are auto enrolled in the same class level, day, and
                time for each upcoming session and tuition is billed on the 1st
                of the month. If you need to make a change to your child's
                class, please contact the front desk.
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        <Disclosure as="div" className="mt-2">
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full pr-4 py-2 text-grey-darker text-left font-bold focus:outline-none">
                <ChevronRightIcon
                  className={`${open ? 'rotate-90' : ''} h-5 w-5`}
                />
                <span className="pl-2">Progressing in Levels</span>
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pt-4 pb-2 text-grey-darker font-light">
                <p>
                  Our Preschool Program is age based and parents can make class
                  changes through the front desk.
                </p>
                <p className="pt-2">
                  Our Recreational Program is skill based. Students will be
                  evaluated on a continuing basis and notified when they are
                  ready to progress. Please contact the front desk to make class
                  changes.
                </p>
                <p className="pt-2">
                  <OutboundLink
                    href={HowDoIProgressPDF}
                    className="font-bold underline-dotted hover:underline-dotted text-orange"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Click here
                  </OutboundLink>{' '}
                  to learn more about class placement.
                </p>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        <Disclosure as="div" className="mt-2">
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full pr-4 py-2 text-grey-darker text-left font-bold focus:outline-none">
                <ChevronRightIcon
                  className={`${open ? 'rotate-90' : ''} h-5 w-5`}
                />
                <span className="pl-2">Makeup Policy</span>
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pt-4 pb-2 text-grey-darker font-light">
                Every student is allowed 1 makeup class (upon availability) or 1
                free open jump pass each month due to an absence. Makeup classes
                need to be scheduled within the month of absence. Please contact
                the front desk to schedule the makeup. We do not credit accounts
                for missed classes.
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        <Disclosure as="div" className="mt-2">
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full pr-4 py-2 text-grey-darker text-left font-bold focus:outline-none">
                <ChevronRightIcon
                  className={`${open ? 'rotate-90' : ''} h-5 w-5`}
                />
                <span className="pl-2">Refund Policy</span>
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pt-4 pb-2 text-grey-darker font-light">
                A credit is placed on your account (prorated depending on the
                drop date) if you decide to remove your child from the program.
                This credit may be used towards future sessions or open jumps.
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        <Disclosure as="div" className="mt-2">
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full pr-4 py-2 text-grey-darker text-left font-bold focus:outline-none">
                <ChevronRightIcon
                  className={`${open ? 'rotate-90' : ''} h-5 w-5`}
                />
                <span className="pl-2">Weather Closure</span>
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pt-4 pb-2 text-grey-darker font-light">
                If classes are canceled due to inclement weather, you will be
                notified via email along with your makeup class options.
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        <Disclosure as="div" className="mt-2">
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full pr-4 py-2 text-grey-darker text-left font-bold focus:outline-none">
                <ChevronRightIcon
                  className={`${open ? 'rotate-90' : ''} h-5 w-5`}
                />
                <span className="pl-2">Attire</span>
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pt-4 pb-2 text-grey-darker font-light">
                Children may wear athletic gear that is not loose-fitting. Socks
                are optional. Leotards are available for purchase in our lobby
                Pro Shop, but are not required. Please have hair tied back and
                no jewelry.
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    </div>
  )
}
