import React from 'react'
import { OutboundLink } from 'gatsby-plugin-google-gtag'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Footer from '../components/footer'
import Copyright from '../components/copyright'
import Header from '../components/header'
import CenterBlock from '../components/center-block'
import Container from '../components/container'
import CardRow from '../components/card-row'
import Card from '../components/card'
import CardBody from '../components/card-body'
import ClassListings from '../components/class-listings'
import Email from '../components/util/email'
import BackgroundImg from '../components/images/classes'
import SessionList from '../components/session-list'
import '../css/classes.css'
import ClassPolicies from '../components/class-policies'
import WhatLevelShouldISignUpForPDF from '../forms/What-Level-Should-I-Sign-Up-For_11-23.pdf'

const ClassesPage = () => (
  <Layout>
    <SEO
      title="Classes & Registration | Jump Around Gymnastics"
      keywords={[
        'gymnastics',
        'gymnastics classes',
        'gymnastics registration',
        'competitive gymnastics',
        'tumbling',
        'cheerleading',
        'cheer camp',
        'summer camp',
        'preschool gymnastics',
        'recreational gymnastics',
        'competitive team gymnastics',
      ]}
      description={`View class descriptions, current openings, and register online for our DeForest and Cottage Grove locations.`}
    />
    <Header>
      <div className="w-full py-16 px-2 text-center text-white text-shadow">
        <h1 className="text-5xl text-display text-center text-white text-shadow uppercase">
          Classes &amp; Registration
        </h1>
        {/* <h2 className="mt-8 text-2xl text-display tracking-wide">Upcoming Sessions</h2> */}
        <div className="block">
          <ul className="list-reset leading-normal">
            <SessionList showNotes={true} liClassName="session-header" />
          </ul>
        </div>
      </div>
    </Header>
    <Container>
      <div className="pt-12">
        <CenterBlock>
          <h2 className="text-3xl font-light pb-8">Gymnastics Programs</h2>
          <p className="text-grey-darker font-light">
            Jump Around Gymnastics offers classes for a variety of ages and
            skill levels. We have Preschool programs that help develop motor
            skills and foster cognitive developments, Recreational programs for
            key skill development, and Team programs for those interested in
            competitive gymnastics.
          </p>
        </CenterBlock>
      </div>
      <CardRow>
        <Card classes="zoom">
          <BackgroundImg
            id={'preschool-image'}
            className={
              'flex flex-wrap items-center h-64 py-6 text-white text-shadow'
            }
            src={'preschoolImage'}
          >
            <div className="w-full text-center">
              <h3 className="text-2xl uppercase">Preschool</h3>
              <span className="font-bold">18 Months &ndash; 5 Years</span>
            </div>
          </BackgroundImg>
          <CardBody>
            <p className="w-full text-center font-bold text-grey-darker mb-4">
              Our Preschool Program is focused on development of coordination
              and strength across three age groups.
            </p>
            <p className="text-grey-darker py-2">
              <span className="font-bold">Tadpoles</span> is a teacher guided
              class with an aid or caregiver for children from crawling to early
              walking. Sensorimotor activities will focus on developing little
              gymnast's relationship between their bodies and environment.
            </p>
            <p className="text-grey-darker py-2">
              <span className="font-bold">Parent/Tot</span> class is
              teacher-guided with the aid of a caregiver. Children, walking to 3
              years old, participate in circuits and follow a gymnastics
              curriculum that helps build coordination, strength, and fine and
              gross motor skills. In addition to the physical activities, there
              is also special attention put towards cognitive and social
              developments.
            </p>
            <p className="text-grey-darker py-2">
              <span className="font-bold">Grasshoppers</span> is an independent
              class for 3 year olds. Structured circuits help students develop
              and hone basic gymnastics skills, while continuing to build body
              awareness, coordination and motor skills. As with all classes,
              gymnastics is used as a tool to teach other skills such as safety,
              resilience, patience, taking turns and self-confidence.
            </p>
            <p className="text-grey-darker py-2">
              <span className="font-bold">Kangaroos</span> class is for 4 &amp;
              5 year olds. Gymnastics stations are introduced to provide
              immediate repetition of the same skill. This class focuses on
              developing proficiency of more complex gymnastics skills such as
              rolls, handstands and cartwheels.
            </p>
          </CardBody>
        </Card>
        <Card classes="zoom">
          <BackgroundImg
            id={'recreational-image'}
            className={
              'flex flex-wrap items-center h-64 py-6 text-white text-shadow'
            }
            src={'recreationalImage'}
          >
            <div className="w-full text-center">
              <h3 className="text-2xl uppercase">Recreational</h3>
              <span className="font-bold">6+ Years</span>
            </div>
          </BackgroundImg>
          <CardBody>
            <p className="w-full text-center font-bold text-grey-darker mb-4">
              Our Recreational Program is for students ages 6 and up, and is
              designed to facilitate growth through milestone skills.
            </p>
            <p className="w-full text-grey-darker py-2">
              Classes are divided into multiple levels based on milestone skills
              that are taught on a continuum. Assessments are conducted each
              session to ensure each athlete is safe and challenged
              appropriately. The curriculum has a balance of fun, excitement,
              and skill development, with the end goal of ensuring a positive
              experience.
            </p>
            <p className="w-full text-grey-darker py-2 font-bold">
              Example Skills By Level
            </p>
            <ol className="pl-4 text-grey-darker leading-tight">
              <li>Handstand, cartwheel, backward roll</li>
              <li>Front handspring, round-off, lunge, handstand to flatback</li>
              <li>
                Back hip circle, handstand to bridge, round-off, rebound,
                backward roll
              </li>
              <li>Tick tock, back handspring, front handspring</li>
              <li>Front handspring, round-off, back handspring, kip</li>
            </ol>
          </CardBody>
        </Card>
        <Card classes="zoom">
          <BackgroundImg
            id={'team-image'}
            className={
              'flex flex-wrap items-center h-64 py-6 text-white text-shadow'
            }
            src={'teamImage'}
          >
            <div className="w-full text-center">
              <h3 className="text-2xl uppercase">Team</h3>
            </div>
          </BackgroundImg>
          <CardBody>
            <p className="w-full text-center font-bold text-grey-darker mb-4">
              Our Team Program is for gymnasts interested in training multiple
              days per week and making a year round commitment.
            </p>
            <p className="w-full text-grey-darker py-2">
              The Competitive Team Program offers year round training with an
              emphasis on form and technique. Gymnasts train multiple days per
              week in preparation for state, regional, and national level
              competition.
            </p>
            <p className="w-full text-grey-darker py-2 font-bold text-center">
              For additional information please email our DeForest office at{' '}
              <br />
              <Email
                classNames="font-bold underline-dotted hover:underline-dotted text-orange"
                addr="DF@JumpAroundGymnastics.com"
              ></Email>
              <br />
              or our Cottage Grove office at
              <br />
              <Email
                classNames="font-bold underline-dotted hover:underline-dotted text-orange"
                addr="CG@JumpAroundGymnastics.com"
              ></Email>
            </p>
          </CardBody>
        </Card>
      </CardRow>
    </Container>
    <section className="bg-white">
      <Container>
        <div className="py-12 px-3 md:px-0">
          <h2 className="text-3xl font-light pb-8">Jump Around Policies</h2>
          <ClassPolicies />
        </div>
      </Container>
    </section>
    <section>
      <Container>
        <div className="pt-12 px-3 md:px-0">
          <h2 className="text-3xl font-light pb-8">Registration</h2>
          <p className="text-grey-darker font-light">
            Browse our class listings below, or use the filters to find a class
            that&apos;s just right for you and your child. Signing up is easy
            and fast with our online registration!
          </p>
          <p className="text-grey-darker font-light mt-2">
            Unsure of your child&apos;s level? Click{' '}
            <OutboundLink
              className="font-bold underline-dotted hover:underline-dotted text-orange"
              href={WhatLevelShouldISignUpForPDF}
            >
              here
            </OutboundLink>{' '}
            or call{' '}
            <OutboundLink
              className="font-bold underline-dotted hover:underline-dotted text-orange"
              href="tel:1-608-846-5867"
            >
              <span className="prefix">(608) 846&#45;5867</span>
            </OutboundLink>{' '}
            to schedule a quick assessment.
          </p>
          <p className="text-grey-darker font-light mt-2">
            Families will be assessed a $30.00 Annual Registration Fee. Students
            are auto enrolled into the next session. Please contact the office
            to make changes.
          </p>
        </div>
        <ClassListings />
      </Container>
    </section>
    <Footer></Footer>
    <Copyright></Copyright>
  </Layout>
)

export default ClassesPage
